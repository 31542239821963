import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jobpriorityIcon from "./../../images/images/jobpriority.png";
import "../../css/templete.css";
import axios from "axios";
import "../Pages/Styles.css";
import { editprofile, fetchprofile } from "../../redux/actions/ProfileActions";
import { logout } from "../../redux/actions/LoginActions";

function Profilesidebar() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const location = useLocation();
  const history = useHistory();
  const [showToggle, setShowToggle] = useState(false);
  const [showToggle1, setShowToggle1] = useState(false);
  const { userInfo } = userLogin;
  const profile = useSelector((state) => state.profile);
  const editProfile = useSelector((state) => state.editprofile);
  const [profilenew, setProfilenew] = useState({
    // fullname: "",
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    gender: "",
    dob: "",
    highest_education: "",
    degree: "",
    stream: "",
    state: "",
    district: "",
    taluka: "",
    registration_id: "",
  });

  useEffect(() => {
    dispatch(fetchprofile());
  }, [editProfile]);
  useEffect(() => {
    console.log(profile?.profileInfo?.data.mobile.length, "112");
    setProfilenew({
      // fullname: "",
      first_name: profile?.profileInfo?.data.first_name
        ? profile?.profileInfo?.data.first_name
        : "",
      last_name: profile?.profileInfo?.data.last_name
        ? profile?.profileInfo?.data.last_name
        : "",
      mobile: profile?.profileInfo?.data.mobile
        ? profile?.profileInfo?.data.mobile.length == 12
          ? profile?.profileInfo?.data.mobile.slice(2)
          : profile?.profileInfo?.data.mobile
        : "",
      email: profile?.profileInfo?.data.email
        ? profile?.profileInfo?.data.email
        : "",
      gender: profile?.profileInfo?.data.gender
        ? profile?.profileInfo?.data.gender
        : "",
      dob: profile?.profileInfo?.data.dob ? profile?.profileInfo?.data.dob : "",
      highest_education: profile?.profileInfo?.data.highest_education
        ? profile?.profileInfo?.data.highest_education
        : "",
      degree: profile?.profileInfo?.data.degree
        ? profile?.profileInfo?.data.degree
        : "",
      stream: profile?.profileInfo?.data.stream
        ? profile?.profileInfo?.data.stream
        : "",
      state: profile?.profileInfo?.data.state
        ? profile?.profileInfo?.data.state
        : "",
      district: profile?.profileInfo?.data.district
        ? profile?.profileInfo?.data.district
        : "",
      taluka: profile?.profileInfo?.data.taluka
        ? profile?.profileInfo?.data.taluka
        : "",
      registration_id: profile?.profileInfo?.data.registration_id
        ? profile?.profileInfo?.data.registration_id
        : "",
      profile: profile?.profileInfo?.data.profile
        ? profile?.profileInfo?.data.profile
        : "",
      is_active: profile?.profileInfo?.data.is_active
        ? profile?.profileInfo?.data.is_active
        : "",
      skills: profile?.profileInfo?.data.skills
        ? profile?.profileInfo?.data.skills
        : "",
    });
  }, [profile]);
  console.log(userInfo, "AAA");

  const handletoggle = () => {
    setShowToggle(!showToggle);
  };
  const handletoggle1 = () => {
    setShowToggle1(!showToggle1);
  };
  function onLogout() {
    dispatch(logout());
    // window.location.reload();
    //history.push('/login')
  }

  const [selectedFile, setSelectedFile] = useState(null);

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilenew({ ...profilenew, profile: file });
      setSelectedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    selectedFile && dispatch(editprofile(profilenew));
  }, [selectedFile]);

  console.log("user::", profile?.profileInfo?.data);

  return (
    <div className="col-xl-3 col-lg-4 m-b30 d-md-block">
      <div className="sticky-top">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div className="canditate-des">
              {/* <Link to={""}> */}
              <img
                alt=""
                src={
                  profilenew.profile
                    ? profilenew.profile
                    : require("./../../images/team/default.png")
                }
                className="img"
              />
              {/* </Link> */}
              <div
                className="upload-link"
                title="update"
                data-toggle="tooltip"
                data-placement="right"
              >
                <input
                  type="file"
                  className="update-flie"
                  id="formFile"
                  onChange={handleFileChange}
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>
            <div className="candidate-title">
              <div className="">
                <h4 className="m-b5">
                  <Link to={"#"}>{userInfo?.Name}</Link>
                </h4>
                <p className="m-b0">
                  {/* <Link to={"#"}>Web developer</Link> */}
                  <span>
                    {profile?.profileInfo?.data.registration_id
                      ? profile?.profileInfo?.data.registration_id
                      : "NA"}
                  </span>
                </p>
                <p className="m-b0">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span className="p-a5">
                    {profile?.profileInfo?.data.email
                      ? profile?.profileInfo?.data.email
                      : "NA"}
                  </span>
                </p>
                <p className="m-b0">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span className="p-a5">
                    {profile?.profileInfo?.data.mobile
                      ? profile?.profileInfo?.data.mobile.length == 12
                        ? profile?.profileInfo?.data.mobile.slice(2)
                        : profile?.profileInfo?.data.mobile
                      : "NA"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <Link
                to={"/profile"}
                className={location.pathname === "/profile" ? "active" : ""}
              >
                <i className="fa fa-user" aria-hidden="true"></i>{" "}
                <span>Profile</span>
              </Link>
            </li>
            <li>
              <Link to={"/jobs-my-resume"}>
                <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                <span>My Resume</span>
              </Link>
            </li>
            <li>
              <Link
                // to={"/opportunity"}
                // className={location.pathname === "/opportunity" ? "active" : ""}
                to={location.pathname}
                className={
                  location.pathname === `/${location.pathname}` ? "active" : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  handletoggle1();
                }}
              >
                <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                <span>Opportunities</span>
              </Link>
              {showToggle1 && (
                <ul>
                  <li style={{ paddingLeft: "0px" }}>
                    <Link
                      to={"/opportunity"}
                      className={
                        location.pathname === "/opportunity" ? "active" : ""
                      }
                    >
                      <i
                        className="fa fa-radio-unchecked"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>Jobs</span>
                    </Link>
                  </li>
                  <li style={{ paddingLeft: "0px" }}>
                    <Link
                      to={"/internships"}
                      className={
                        location.pathname === "/internships" ? "active" : ""
                      }
                    >
                      <i
                        className="fa fa-radio-unchecked"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>Internships</span>
                    </Link>
                  </li>
                  {/* <li style={{ paddingLeft: "0px" }}>
                    <Link
                      to={"/events-jobs"}
                      className={
                        location.pathname === "/events-jobs" ? "active" : ""
                      }
                    >
                      <i
                        className="fa fa-radio-unchecked"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>Jobs</span>
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            {/* <li>
              <Link
                to={"/skilling"}
                className={location.pathname === "/skilling" ? "active" : ""}
              >
                <i className="fa fa-graduation-cap" aria-hidden="true"></i>{" "}
                
                <span>Skiling Opportunities</span>
              </Link>
            </li> */}
            <li>
              <Link
                // to={`/${location.pathname}`}
                to={location.pathname}
                className={
                  location.pathname === `/${location.pathname}` ? "active" : ""
                }
                onClick={(e) => {
                  e.preventDefault();
                  handletoggle();
                }}
              >
                <i className="fa fa-university" aria-hidden="true"></i>{" "}
                <span>Events</span>
              </Link>
              {showToggle && (
                <ul>
                  <li style={{ paddingLeft: "0px" }}>
                    <Link
                      to={"/events-jobfair"}
                      className={
                        location.pathname === "/events-jobfair" ? "active" : ""
                      }
                    >
                      <i
                        className="fa fa-radio-unchecked"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>Job Fairs</span>
                    </Link>
                  </li>
                  {/* <li style={{ paddingLeft: "0px" }}>
                    <Link
                      to={"/events-jobs"}
                      className={
                        location.pathname === "/events-jobs" ? "active" : ""
                      }
                    >
                      <i
                        className="fa fa-radio-unchecked"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>Jobs</span>
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            {/* <li>
              <Link
                to={"/job-priority"}
                className={
                  location.pathname === "/job-priority" ? "active" : ""
                }
              >
                <i className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                <span>Job Priority Status</span>
              </Link>
            </li> */}
            <li>
              <Link
                to={"/faq"}
                className={location.pathname === "/faq" ? "active" : ""}
              >
                <i className="fa fa-comments" aria-hidden="true"></i>{" "}
                <span>FAQ</span>
              </Link>
            </li>
            {/* <li>
              <Link to={"/contact-us"}>
                <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                <span>Contact Us</span>
              </Link>
            </li> 
            <li><Link to={"/jobs-change-password"}>
							<i className="fa fa-key" aria-hidden="true"></i> 
							<span>Change Password</span></Link></li>

            */}
            <li onClick={() => onLogout()}>
              <Link to={"/login"}>
                <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                <span>Log Out</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Profilesidebar;
