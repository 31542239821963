import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Profilesidebar from "../Element/Profilesidebar";

const jobAlert = [
  { id: 1, title: "Social Media Expert", date: "December 15,2018" },
  { id: 2, title: "Web Designer", date: "November 10,2018" },
  { id: 3, title: "Finance Accountant", date: "October 5,2018" },
  { id: 4, title: "Social Media Expert", date: "December 15,2018" },
  { id: 5, title: "Web Designer", date: "November 10,2018" },
  { id: 6, title: "Finance Accountant", date: "October 5,2018" },
  { id: 7, title: "Social Media Expert", date: "December 15,2018" },
  { id: 8, title: "Web Designer", date: "November 10,2018" },
  { id: 9, title: "Finance Accountant", date: "October 5,2018" },
  { id: 10, title: "Social Media Expert", date: "December 15,2018" },
];

function Jobsalert({ location }) {
  const [company, setCompany] = useState(false);
  const [contacts, setContacts] = useState(jobAlert);
  const [toggle, setToggle] = useState(0);
  // delete data
  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === contactId);
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  const faq = [
    {
      id: 1,
      question: "How to use this app for my career growth?",
      answer:
        "Start chatting with the app, and wait for its notifications. We will notify you of suitable work, self-employment, or skilling opportunities at regular intervals. Use the app to check the status of various opportunities you may have applied for.",
    },
    {
      id: 2,
      question: "What exactly is the Saksham Guide? Is it a bot?",
      answer:
        "Saksham is a mission by TIFAC, an autonomous body under Government of India, to end unemployment. It does so by guiding every individual towards the right opportunities. This guide is a bot that is going to be there for your lifetime helping you with the right skills and the right employment. This bot is also supported by human counsellors who are there to help you. ",
    },
    {
      id: 3,
      question: "How will it guide me for a lifetime?",
      answer:
        "We are here to recommend the right opportunity for you. We guide you through every step of your life to help develop a stable and secure career.",
    },
    {
      id: 4,
      question: "I am not getting guidance on the chat, what should I do?",
      answer:
        "We have recorded your message. Sometimes, we may take a few working days to come up with something that suits you. Please wait for some more time. If there is no response for a long time, contact us on our number, and we will help you.",
    },
    {
      id: 5,
      question: "I am not seeing any active opportunities, what is the reason?",
      answer:
        "It is possible that the opportunity that you applied for has been closed. But don’t worry, we will keep coming up with opportunities that are relevant to you.",
    },
    {
      id: 6,
      question:
        "I’ve received an opportunity, but it's not paying me enough. How does it suit me?",
      answer:
        "Do you want to earn something in the short term, or build a career which will give you much more in the long run? We help you get quality employment and the steps we recommend help you reach there.",
    },
  ];

  const [id, setId] = useState(null);
  console.log(id, "111");

  return (
    <>
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx table-job-bx browse-job clearfix">
                    <label>
                      Get guided by India’s apex mission to end unemployment
                    </label>
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        FAQ
                      </h5>
                    </div>

                    {/* <div className="job-bx-text clearfix">
                      
                      <label>Need Help?</label>
                      <br />
                      <label>
                        Contact us for all your queries or feedback.
                      </label>
                      <p>
                        Facing any issues or just want to share your feedback
                        about your experiences. feel free to connect with us via
                        email- Samarth@gmail.com
                      </p>
                    </div> */}

                    <div className="job-bx-text clearfix">
                      <label>FAQs</label>
                      <br />
                      <label>Check if FAQs can solve your query.</label>
                    </div>
                    <div>
                      {faq.map((el, i) => {
                        return (
                          <div key={i}>
                            <div
                              className="post-bx mb-2"
                              // style={{ cursor: "pointer" }}
                              // onClick={() => setId(el.id)}
                            >
                              <div className="job-post-info m-a0">
                                <div className="posted-info clearfix">
                                  <p className="m-tb0 text-primary float-left">
                                    <span className="text-black m-r10">
                                      {el.question}
                                    </span>
                                  </p>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    className="float-right m-0 mr-2"
                                  >
                                    {el.id !== id ? (
                                      <i
                                        className={"fa fa-chevron-down"}
                                        onClick={() => setId(el.id)}
                                      ></i>
                                    ) : (
                                      <i
                                        className={"fa fa-chevron-up"}
                                        onClick={() => {
                                          setId("");
                                        }}
                                      ></i>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {id === el.id && (
                              <div className="job-bx-text clearfix ml-2">
                                <label>Answer:</label>
                                <br />
                                <p>{el.answer}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Jobsalert;
