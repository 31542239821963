import React, { useEffect, useState, useRef } from "react";
//import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import "../Pages/Styles.css";

// image
//import logo from "../../images/logo-full-white.png";
// import loginbg from "./../../images/bg6.jpg";
// import logo from "./../../images/images/Group 27363.png";
import loginbg from "./../../images/background/bg7.png";
import logo2 from "./../../images/logo-white2.png";
import logo from "./../../images/logo/SakshamLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { login, loginOTP } from "../../redux/actions/LoginActions";
import axios from "axios";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "", otp: "" };
  const [dummy, setDummy] = useState(1);
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [toggle, setToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push("/opportunity");
    }
  }, [userInfo, history]);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      //   errorObj.email = "Email is Required";
      errorObj.email = "Mobile Number is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      console.log("error login::", error);
      return;
    } else {
      //history.push('/')
      dispatch(login(email, password));
      const { error, loading } = userLogin;
      console.log("after login::", error, loading);
      /*
			if (loading===true){
				swal('Please wait!', 'Loading...', );
			}
			else if (loading === false && error!=="") {
				swal('Error!', 'Something Went Wrong!', "error");
			}
			*/
    }
    //dispatch(loadingToggleAction(true));
    //dispatch(loginAction(email, password, props.history));
  }
  function onLoginOTP(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    const otpValue = otp.join("");
    // if (otpValue.length === 6) {
    //   alert(`OTP Submitted: ${otpValue}`);
    // } else {
    //   alert("Please enter a valid 6-digit OTP.");
    // }
    if (email === "") {
      //   errorObj.email = "Email is Required";
      errorObj.email = "Mobile Number is Required";
      error = true;
    }
    if (otpValue.length !== 6 || otpValue === "") {
      errorObj.otp = "Please enter a valid 4-digit OTP";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      //history.push('/')
      dispatch(loginOTP(email, otp));
      const { error, loading } = userLogin;
      /*
			if (loading===true){
				swal('Please wait!', 'Loading...', );
			}
			else if (loading === false && error!=="") {
				swal('Error!', 'Something Went Wrong!', "error");
			}
			*/
    }
    //dispatch(loadingToggleAction(true));
    //dispatch(loginAction(email, password, props.history));
  }

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return; // Only allow numbers

    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Only allow 1 digit
    setOtp(newOtp);

    // Move focus to next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };
  const sendOTP = async () => {
    try {
      if (email.length === 10) {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/send-otp`,
            {
              email: email,
            }
          )
          .then((res) => {
            console.log("otp send:", res);
            Swal.fire({
              icon: "success",
              title: res.data?.message
                ? res.data?.message
                : "Otp sent successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                popup: "my-swal-center",
              },
            });
          })
          .catch((err) => {
            console.log("err otp:", err);
            Swal.fire({
              icon: "error",
              title: err.response?.message
                ? err.response?.message
                : "Otp sent successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                popup: "my-swal-center",
              },
            });
          });
      }
    } catch (error) {
      let error1 = false;
      const errorObj = { ...errorsObj };
      errorObj.email = "Mobile Number is Required";
      error1 = true;
    }
  };
  useEffect(() => {
    setEmail("");
    setErrors({ email: "", password: "", otp: "" });
    setPassword("");
    setOtp(["", "", "", "", "", ""]);
  }, [toggle]);
  return (
    <div className="page-wraper">
      <div
        className={dummy === 1 ? "" : "page-content bg-white login-style2"}
        style={{
          backgroundImage: "url(" + loginbg + ")",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {dummy === 1 ? (
          <div className="forgot-row">
            <div className="forgot-container">
              <div className="login-2 submit-resume p-a30 seth">
                <form
                  onSubmit={!toggle ? onLogin : onLoginOTP}
                  className="col-12 p-a0 "
                >
                  <div className="form-logo-div">
                    <img src={logo} alt="" height={"42.8px"} />
                  </div>

                  <p className="font-weight-600">
                    If you have an account with us, please log in.
                  </p>
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-80">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  {/* <div className="form-group ">
                        <label>E-Mail Address*</label>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Type Your Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div> */}
                  <p
                    className="font-weight-600 text-center toggle"
                    onClick={() => setToggle(!toggle)}
                  >
                    {!toggle ? "Login via OTP" : "Login via Password"}
                  </p>
                  <div className="form-group ">
                    <label>Mobile Number/Email*</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type Your 10 Digit Mobile Number or Email id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // maxLength={10}
                      />
                    </div>
                    {toggle && (
                      <p
                        className="font-weight-300 font-14 float-right toggle m-b10"
                        onClick={() => sendOTP()}
                      >
                        {"Get OTP"}
                      </p>
                    )}
                    {errors.email && (
                      <div className="text-danger fs-12">{errors.email}</div>
                    )}
                  </div>
                  {!toggle ? (
                    <div className="form-group">
                      <label>Password *</label>
                      <div className="input-group show-eye">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          value={password}
                          placeholder="Type Your Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="input-group-text-eye"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="form-group m-t15">
                      <label>Enter the OTP *</label>
                      <div className="input-group-otp">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            value={digit}
                            maxLength="1"
                            onChange={(e) => handleChange(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            // style={{ width: "40px", textAlign: "center" }}
                            className="form-control"
                            required
                          />
                        ))}
                      </div>
                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="text-center-section">
                    <button className="site-button float-left">Login</button>
                    <Link
                      to="register"
                      className="site-button-link forget-pass m-t15 float-right"
                    >
                      <i className="fa fa-unlock-alt"></i> Sign up
                    </Link>
                  </div>
                  <div className="text-center-section">
                    {/* <button className="site-button float-left">
                          login
                        </button> */}
                    <Link
                      to="forgot-password"
                      className="site-button-link forget-pass m-t15 float-center"
                    >
                      <i className="fa fa-unlock-alt"></i> Forgot Password
                    </Link>
                  </div>
                  {/* <div className="text-center">
                        <span className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="check1"
                            name="example1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="check1"
                          >
                            Remember me
                          </label>
                        </span>
                        <Link
                          data-toggle="tab"
                          to="#forgot-password"
                          className="forget-pass m-l5"
                        >
                          <i className="fa fa-unlock-alt"></i> Forgot Password
                        </Link>
                      </div> */}
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="section-full">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-6 col-md-6 d-flex">
                <div className="text-white max-w400 align-self-center">
                  <h2 className="m-b10">Login To You Now</h2>
                  <p className="m-b30">
                    Connecting Opportunities. Empowering Careers.
                  </p>

                  <ul className="list-inline m-a0">
                    <li>
                      <Link to={""} className="m-r10 text-white ">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={""} className="m-r10 text-white ">
                        <i className="fa fa-google-plus"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={""} className="m-r10 text-white ">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={""} className="m-r10 text-white ">
                        <i className="fa fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={""} className="m-r10 text-white">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}

                <div className="col-lg-6 col-md-6">
                  <div className="login-2 submit-resume p-a30 seth">
                    <div className="nav">
                      <form
                        onSubmit={!toggle ? onLogin : onLoginOTP}
                        className="col-12 p-a0 "
                      >
                        <div className="form-logo-div">
                          <img src={logo} alt="" height={"42.8px"} />
                        </div>

                        <p className="font-weight-600">
                          If you have an account with us, please log in.
                        </p>
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-80">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        {/* <div className="form-group ">
                        <label>E-Mail Address*</label>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Type Your Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div> */}
                        <p
                          className="font-weight-600 text-center toggle"
                          onClick={() => setToggle(!toggle)}
                        >
                          {!toggle ? "Login via OTP" : "Login via Password"}
                        </p>
                        <div className="form-group ">
                          <label>Mobile Number*</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type Your 10 Digit Mobile Number"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              // maxLength={10}
                            />
                          </div>
                          {toggle && (
                            <p
                              className="font-weight-300 font-14 float-right toggle m-b10"
                              onClick={() => sendOTP()}
                            >
                              {"Get OTP"}
                            </p>
                          )}
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        {!toggle ? (
                          <div className="form-group">
                            <label>Password *</label>
                            <div className="input-group show-eye">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                value={password}
                                placeholder="Type Your Password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span
                                className="input-group-text-eye"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <i
                                  className={
                                    showPassword
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="form-group m-t15">
                            <label>Enter the OTP *</label>
                            <div className="input-group-otp">
                              {otp.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  value={digit}
                                  maxLength="1"
                                  onChange={(e) => handleChange(e, index)}
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  // style={{ width: "40px", textAlign: "center" }}
                                  className="form-control"
                                  required
                                />
                              ))}
                            </div>
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        )}

                        <div className="text-center-section">
                          <button className="site-button float-left">
                            login
                          </button>
                          <Link
                            to="register"
                            className="site-button-link forget-pass m-t15 float-right"
                          >
                            <i className="fa fa-unlock-alt"></i> Sign up
                          </Link>
                        </div>
                        <div className="text-center-section">
                          {/* <button className="site-button float-left">
                          login
                        </button> */}
                          <Link
                            to="forgot-password"
                            className="site-button-link forget-pass m-t15 float-center"
                          >
                            <i className="fa fa-unlock-alt"></i> Forgot Password
                          </Link>
                        </div>
                        {/* <div className="text-center">
                        <span className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="check1"
                            name="example1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="check1"
                          >
                            Remember me
                          </label>
                        </span>
                        <Link
                          data-toggle="tab"
                          to="#forgot-password"
                          className="forget-pass m-l5"
                        >
                          <i className="fa fa-unlock-alt"></i> Forgot Password
                        </Link>
                      </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*
				<footer className="login-footer">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<span className="float-left">© Copyright by <i className="fa fa-heart m-lr5 text-red heart"></i>
								<Link to={"#"}>DexignZone </Link> </span>
								<span className="float-right">
									All rights reserved.
								</span>
							</div>
						</div>
					</div>
				</footer>
				*/}
      </div>
    </div>
  );
}

/*
const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
*/
export default Login;
