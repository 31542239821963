// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { forgot_password, register } from "../../redux/actions/LoginActions";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import loginbg from "./../../images/bg6.jpg";
import loginbg from "./../../images/background/bg7.png";
// import logo from "./../../images/images/Group 27363.png";
import logo from "./../../images/logo/SakshamLogo.svg";
import "./Styles.css";

const ForgotPassword = (props) => {
  const [name, setName] = useState("");
  const [lname, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  let errorsObj = {
    name: "",
    lname: "",
    mobile: "",
    email: "",
    password: "",
    confirmpassword: "",
    code: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const userRegister = useSelector((state) => state.userRegister);
  const verData = useSelector((state) => state.verData);
  const { error, loading, userInfo } = userRegister;
  const [screen, setScreen] = useState("email");
  const [screen2, steScreen2] = useState("verified");

  console.log(verData, "ut22");

  const dispatch = useDispatch();
  const history = useHistory();

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (mobile === "") {
      errorObj.mobile = "Email Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    //dispatch(loadingToggleAction(true));
    //dispatch(signupAction(email, password, props.history));
    dispatch(register(name, lname, email, password, mobile, password));
    //history.push('/login')
  }

  const submitMobile = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (mobile === "") {
      errorObj.mobile =
        screen == email ? "Email is Required" : "Mobile Number is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      forgot_password({
        type: 1,
        email: mobile,
        // verification_code: "",
        // password: "",
      })
    );
    // steScreen2("verified1");
  };

  useEffect(() => {
    screen2 == "verified" &&
      verData?.verData?.message &&
      steScreen2("verified1");

    screen2 === "verified1" &&
      verData?.verData?.message &&
      history.push("/login");
  }, [verData]);

  const submit2 = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    console.log(errorObj);
    if (code === "") {
      errorObj.code = "Enter verification code";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is required";
      error = true;
    }
    if (confirmpassword === "") {
      errorObj.confirmpassword = "Type password again";
      error = true;
    }
    if (confirmpassword !== password) {
      errorObj.confirmpassword = "Check password again";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      forgot_password({
        type: 2,
        verification_code: code,
        password: password,
        email: mobile,
      })
    );
  };

  return (
    <div className="page-wraper">
      {/* <div className="browse-job login-style3"> */}
      <div
        // className="page-content bg-white login-style2"
        // className="page-content login-style2"
        style={{
          backgroundImage: "url(" + loginbg + ")",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="forgot-row">
          <div className="forgot-container">
            {screen2 == "verified" ? (
              <div className="login-2 submit-resume p-a30 seth">
                {screen == "email" ? (
                  <form
                    onSubmit={submitMobile}
                    className="col-lg-12 col-sm-12 p-a0"
                  >
                    <div className="form-logo-div">
                      <img src={logo} alt="" height={"42.8px"} />
                    </div>

                    {/* <p className="font-weight-600">
                      If you have an account with us, please log in.
                    </p> */}
                    {/* {props.errorMessage && (
                      <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-80">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )} */}

                    <label>Email</label>
                    <div className="form-group">
                      <input
                        type="email"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="form-control"
                        placeholder="Enter Email ID"
                      />
                      <div className="text-danger">
                        {errors.mobile && <div>{errors.mobile}</div>}
                      </div>
                    </div>

                    {/* <label >
                      Password
                    </label>
                    <div className="form-group">
                      <input
                        type="password"
                        value={password}
                        className="form-control"
                        defaultValue="Password"
                        placeholder="Type Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="text-danger">
                        {errors.password && <div>{errors.password}</div>}
                      </div>
                    </div>

                    <label >
                      Confirm Password
                    </label>
                    <div className="form-group">
                      <input
                        type="password"
                        value={confirmpassword}
                        className="form-control"
                        defaultValue="Password"
                        placeholder="Type Password Again"
                        onChange={(e) => setConfirmpassword(e.target.value)}
                      />
                      <div className="text-danger">
                        {errors.confirmpassword && (
                          <div>{errors.confirmpassword}</div>
                        )}
                      </div>
                    </div> */}
                    <div className="form-group text-center d-grid">
                      <button
                        type="submit"
                        className="site-button dz-xs-flex m-r5 w-100"
                      >
                        Submit
                      </button>

                      <div className="flex-gap justify-content-center my-4">
                        <p className="mb-0">Back to</p>
                        <Link to="/login">
                          <p className="mb-0">Login</p>
                        </Link>
                      </div>

                      <p
                        className="text-link"
                        onClick={() => setScreen("mobile")}
                      >
                        Try another way
                      </p>
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={submitMobile}
                    className="col-lg-12 col-sm-12 p-a0 "
                  >
                    <div className="form-logo-div">
                      <img src={logo} alt="" height={"42.8px"} />
                    </div>
                    <div className="my-2 ">
                      {/* <p class="font-weight-bold font-fifteen">
                      Enter Your Aadhar card linked Mobile Number{" "}
                    </p> */}
                      <div className="my-4">
                        <label>Mobile Number</label>
                        <div className="form-group">
                          <input
                            type="tel"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            className="form-control"
                            placeholder="Type Your 10 Digit Mobile Number"
                          />
                          <div className="text-danger">
                            {errors.mobile && <div>{errors.mobile}</div>}
                          </div>
                        </div>
                        <div className="form-group text-center d-grid">
                          <button
                            type="submit"
                            className="site-button dz-xs-flex m-r5 w-100"
                          >
                            Submit
                          </button>

                          <div className="flex-gap justify-content-center my-4">
                            <p className="mb-0">Back to</p>
                            <Link to="/login">
                              <p className="mb-0">Login</p>
                            </Link>
                          </div>

                          <p
                            className="text-link"
                            onClick={() => setScreen("email")}
                          >
                            Try another way
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            ) : (
              <div className="login-2 submit-resume p-a30 seth">
                <form onSubmit={submit2} className="col-lg-12 col-sm-12 p-a0 ">
                  <div className="form-logo-div">
                    <img src={logo} alt="" height={"42.8px"} />
                  </div>

                  {/* <p className="font-weight-600">
                      If you have an account with us, please log in.
                    </p> */}

                  <label>Verification Code</label>
                  <div className="form-group">
                    <input
                      type="number"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      className="form-control"
                      placeholder="Enter Verification Code"
                    />
                    <div className="text-danger">
                      {errors.code && <div>{errors.code}</div>}
                    </div>
                  </div>

                  <label>Password</label>
                  <div className="form-group">
                    <input
                      type="password"
                      value={password}
                      className="form-control"
                      defaultValue="Password"
                      placeholder="Type Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="text-danger">
                      {errors.password && <div>{errors.password}</div>}
                    </div>
                  </div>

                  <label>Confirm Password</label>
                  <div className="form-group">
                    <input
                      type="password"
                      value={confirmpassword}
                      className="form-control"
                      defaultValue="Password"
                      placeholder="Type Password Again"
                      onChange={(e) => setConfirmpassword(e.target.value)}
                    />
                    <div className="text-danger">
                      {errors.confirmpassword && (
                        <div>{errors.confirmpassword}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center d-grid">
                    <button
                      type="submit"
                      className="site-button dz-xs-flex m-r5 w-100"
                    >
                      Submit
                    </button>

                    {/* <div className="flex-gap justify-content-center my-4">
                      <p className="mb-0">Back to</p>
                      <Link to="/login">
                        <p className="mb-0">Login</p>
                      </Link>
                    </div> */}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
