import axios from "axios";
import Swal from "sweetalert2";
import "../../markup/Pages/Styles.css";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
} from "../constants/LoginConstants";

export const login = (email, password) => async (dispatch) => {
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
    position: "center",
    // customClass: {
    //   popup: "my-swal-center",
    // },
  });
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/jobseeker/login`,
      //   `${process.env.REACT_APP_BACKEND_WEB_URL}/sapiobot/login_via_password`,
      {
        // mobnum: `${email}`,
        // password: password,
        //returnSecureToken: true,
        email: `${email}`,
        password: password,
      }
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "success",
      title: "Login successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });

    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error?.response?.data["Developer Message"]
        ? error?.response?.data["Developer Message"] ===
          "Channel Partner Not Registered "
          ? "Jobseeker not registered"
          : error?.response?.data["Developer Message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};

export const loginOTP = (email, otp) => async (dispatch) => {
  console.log(otp.join(""), "oottpp");
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
    // customClass: {
    //   popup: "my-swal-center",
    // },
  });
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      //   `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/jobseeker/login`,
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/login-otp`,
      {
        email: email,
        otp: otp.join(""),
        //returnSecureToken: true,
      }
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "success",
      title: "Login successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });

    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error?.response?.data["message"]
        ? error?.response?.data["message"] === "Channel Partner Not Registered "
          ? "Jobseeker not registered"
          : error?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};

export const logout = () => (dispatch) => {
  sessionStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const register =
  (name, last, email, password, mobile, dob, gender, highest_education) =>
  async (dispatch) => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/new/jobseeker/register`,
        {
          email: email,
          password: password,
          mobile: mobile,
          first_name: name,
          last_name: last,
          dob: dob,
          gender: gender,
          highest_education: highest_education,
        }
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      setTimeout(() => {
        waitingPopup.close();
      }, 0);

      Swal.fire({
        icon: "success",
        title: "Register successfully",
        //text: res.data["User Message"],
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response.data,
      });

      setTimeout(() => {
        waitingPopup.close();
      }, 0);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data["Developer Message"]
          ? error?.response?.data["Developer Message"]
          : "Something went wrong!",
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    }
  };

export const forgot_password = (details) => async (dispatch) => {
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/forget-password`,
      details
    );
    console.log(data, "dd12");
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "success",
      title: data?.message,
      //text: res.data["User Message"],
      showConfirmButton: true,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data,
    });

    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error?.response?.data["message"]
        ? error?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      // customClass: {
      //   popup: "my-swal-center",
      // },
      position: "center",
    });
  }
};
